<template>
    <div class='phoneCallRecording'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <el-date-picker v-model="paginationInfo.condition.startTime" clearble align="right" type="date"
                        placeholder="选择呼入时间" :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.status" placeholder="请选择状态" @change="queryList"
                        filterable clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已处理" value="1"></el-option>
                        <el-option label="未处理" value="0"></el-option>
                    </el-select>
                    <el-input style="width:200px;" v-model="paginationInfo.condition.callerNo" placeholder="请输入来电号码"
                        clearable></el-input>
                    <el-input style="width:200px;" v-model="paginationInfo.condition.ivrName" placeholder="请输入导航名称"
                        clearable></el-input>
                    <el-button @click="queryList">查询</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <!-- <el-table-column prop="lsh" label="话单流水号" align='center'>
                </el-table-column> -->
                <el-table-column prop="numberCode" label="400号码" align='center'>
                </el-table-column>
                <el-table-column prop="callerNo" label="来电号码" align='center'>
                </el-table-column>
                <el-table-column label="呼入400时间" align='center'>
                    <template slot-scope="scope">
                        {{ $moment(scope.row.incomingTime).calendar() }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="接听时间" align='center'>
                    <template slot-scope="scope">
                        {{ $moment(scope.row.startTime).calendar() }}
                    </template>
                </el-table-column> -->
                <el-table-column prop="minutes" label="通话时长" align='center'>
                </el-table-column>
                <!-- <el-table-column prop="talkInterval" label="扣费时长 " align='center'>
                </el-table-column> -->
                <el-table-column prop="ivrName" label="导航名称" align='center'>
                </el-table-column>
                <!-- <el-table-column prop="agentId" label="坐席id" align='center'>
                </el-table-column> -->
                <el-table-column prop="calleeNo" label="坐席号码" align='center'>
                </el-table-column>
                <!-- <el-table-column prop="linkman" label="联系人名称" align='center'>
                </el-table-column> -->
                <el-table-column prop="district" label="归属地" align='center'>
                </el-table-column>
                <!-- <el-table-column prop="userName" label="坐席名称" align='center'>
                </el-table-column> -->
                <!-- <el-table-column label="挂机时间" align='center'>
                    <template slot-scope="scope">
                        {{ $moment(scope.row.onhookTime).calendar() }}
                    </template>
                </el-table-column> -->
                <el-table-column label="满意度值" align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.result == 0">未填写</span>
                        <span v-else-if="scope.row.result == 1">非常满意</span>
                        <span v-else-if="scope.row.result == 2">满意</span>
                        <span v-else-if="scope.row.result == 3">不满意</span>
                    </template>
                </el-table-column>
                <el-table-column label="通话类型 " align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.talkType == 1">未接</span>
                        <span v-else-if="scope.row.talkType == 2">已接</span>
                        <span v-else-if="scope.row.talkType == 3">留言</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态 " align='center'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">已处理</span>
                        <span v-else-if="scope.row.status == 0">未处理</span>
                    </template>
                </el-table-column>
                <el-table-column prop="handle_msg" label="处理结果" align='center'>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]"
                :page-size="paginationInfo.pageSize" :total="paginationInfo.total"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isHandleL: false,
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    incomingTime: "",
                    // app_code: "languagemember",
                    // mobile: '',
                    // status: '',
                }
            },
            tableData: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        this.paginationInfo.condition.begin_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.getManagerRecords();
    },
    mounted() {
    },
    methods: {
        queryList() {
            this.paginationInfo.pageNo = 1;
            this.getManagerRecords()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getManagerRecords();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getManagerRecords();
        },
        getManagerRecords() {
            this.$service.OnlineOrderService
                .getManagerRecords(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data;
                        this.paginationInfo.total = dataInfo.total || 1000;
                        this.tableData = dataInfo.records;
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>

<style lang='less' scoped>
.phoneCallRecording {}
</style>