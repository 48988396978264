import { render, staticRenderFns } from "./phoneCallRecording.vue?vue&type=template&id=5912ae42&scoped=true"
import script from "./phoneCallRecording.vue?vue&type=script&lang=js"
export * from "./phoneCallRecording.vue?vue&type=script&lang=js"
import style0 from "./phoneCallRecording.vue?vue&type=style&index=0&id=5912ae42&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5912ae42",
  null
  
)

export default component.exports